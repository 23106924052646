import { RootState } from "../../../../../redux/store";
import * as constants from "./constants";

export const isItemsLoading = (state:RootState) => state.tasks.itemsLoading;
export const isItemActionLoading = (state:RootState) => state.tasks.actionsLoading;
export const getItems = (state:RootState) => state.tasks.items;
export const getItemsCount = (state:RootState) => state.tasks.itemsCount;
export const getItemForEdit = (state:RootState) => state.tasks.itemForEdit;
export const getError = (state:RootState) => state.tasks.errorAction;

export const getTaskSurveys = (state:RootState) => state.tasks[constants.TASK_SURVEYS];
export const getTaskSurveysLoading = (state:RootState) => state.tasks[constants.TASK_SURVEYS_LOADING];

export const getCustomers = (state:RootState) => state.tasks[constants.CUSTOMERS];
export const getCustomersCount = (state:RootState) => state.tasks[constants.CUSTOMERS_COUNT];
export const getCustomersLoading = (state:RootState) => state.tasks[constants.CUSTOMERS_LOADING];

export const getTaskOrigin = (state:RootState) => state.tasks[constants.TASK_ORIGIN];

export const validationErrors = (state:RootState) => state.tasks.validationErrors;

export const getFilters = (state:RootState) => state.tasks.filters;

export const getRejectionReasonsLoading = (state:RootState) => state.tasks[constants.REJECTION_REASONS_LOADING];
export const getRejectionReasons = (state:RootState) => state.tasks[constants.REJECTION_REASONS];

export const getTaskDetails = (state:RootState) => state.tasks[constants.TASK_DETAILS];

export const getTaskOrderDetails = (state: RootState) => state.tasks[constants.TASK_ORDER]?.details;
export const getTaskOrderDetailsTotalSums = (state: RootState) => state.tasks[constants.TASK_ORDER]?.totalSums;
export const getTaskOrderDetailsLoading = (state: RootState) => state.tasks[constants.TASK_ORDER_LOADING];

export const getTaskCategories = (state:RootState) => state.tasks[constants.TASK_CATEGORIES];

export const getContactPersonsForCustomer = (state:RootState) => state.tasks[constants.CUSTOMER_CONTACT_PERSONS];
export const getContactPersonsFor = (state:RootState) => state.tasks[constants.CUSTOMER_CONTACT_FOR]
export const getCustomerDropdownLoading = (state:RootState) => state.tasks[constants.CUSTOMER_CONTACT_FOR_LOADING]

export const customerDevelopmentTypes = (state:RootState) => state.tasks[constants.CUSTOMER_DEVELOPMENT_TYPES]
export const customerDevelopmentTypesLoading = (state:RootState) => state.tasks[constants.CUSTOMER_DEVELOPMENT_TYPES_LOADING]