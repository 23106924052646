import { useEffect, lazy } from "react";

import { Navigate, Route } from "react-router-dom"
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "./common/_customHooks/redux";
import { LayoutSplashScreen } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { useUserOrganizationUnitHook } from "./user-access/useUserOrganizationUnitHook";
import { actions } from "../app/common/_redux";
import { selectors } from "../app/common/_redux";
import { selectors as userAccessSelectors } from "../app/user-access/_redux";

import { PATHS as IRPRODUCTS_PATHS } from './modules/IRProducts/constants';
import { PATHS as DATA_CONFIG_PATHS } from './modules/DataConfiguration/constants';
import { PATHS as PLANOGRAM_PATHS } from './modules/Planogram/constants';
import { PATHS as WEBSHOP_PATHS } from './modules/WebShop/constants';
import { PATHS as TASK_PANNING_PATHS } from './modules/TaskPlanning/constants';
import { PATHS as ASSET_MANAGEMENT_PATHS } from './modules/AssetManagement/constants';

const DashboardRouter = lazy(() => import("./modules/Dashboard/DashboardRouter"));
const MasterDataRouter = lazy(() => import("./modules/MasterData/pages/MasterDataRouter"));
const TaskPlanningRouter = lazy(() => import("./modules/TaskPlanning/TaskPlanningRouter"));
const AttachmentsRouter = lazy(() => import("./modules/Attachments/AttachmentsRouter"));
const RealTimeRecognitionRouter = lazy(() => import("./modules/RealTimeRecognition/RealTimeRecognitionRouter"));
const AssetManagementRouter = lazy(() => import("./modules/AssetManagement/AssetManagementRouter"));
const IRProductsRouter = lazy(() => import('./modules/IRProducts/IRProductsRouter'));
const PlanogramRouter = lazy(() => import("./modules/Planogram/PlanogramRouter"));
const DataConfigurationPage = lazy(() => import('./modules/DataConfiguration/DataConfigurationPage'));
const UsersManagementRouter = lazy(() => import("./modules/UsersManagement/pages/UsersManagementRouter"));
const SettingsPage = lazy(() => import("./modules/Settings/page/settingsPage"));
const WebShopRouter = lazy(() => import('./modules/WebShop/WebShopRouter'));


export default function BasePage() {
  const [userOrganizationUnit] = useUserOrganizationUnitHook();
  const dispatch = useDispatch();
  const swaggerValidationSchemas = useSelector(selectors.swaggerValidationSchemas)
  const menuItems = useSelector(userAccessSelectors.getMenu)
  
  useEffect(() => {
    if(Object.keys(swaggerValidationSchemas).length === 0) {
      dispatch(actions.fetchSwaggerSchemas);
    }
  }, []);

  let defaultPage = '/'

  if (menuItems.length > 0) {
    defaultPage = menuItems[0].url;
  }
  

  return userOrganizationUnit ? (
    <>
      <Route index element={<Navigate to={defaultPage} />}/>
      <Route path="builder/*" element={<BuilderPage />} />
      <Route path="dashboard/*" element={<DashboardRouter/>} />
      <Route path="masterdata/*" element={<MasterDataRouter/>} />
      <Route path={`${TASK_PANNING_PATHS.ROOT}/*`} element={<TaskPlanningRouter/>} />
      <Route path="attachment/*" element={<AttachmentsRouter/>} />
      <Route path="rtr-monitoring/*" element={<RealTimeRecognitionRouter/>} />
      <Route path={`${ASSET_MANAGEMENT_PATHS.ROOT}/*`} element={<AssetManagementRouter/>} />
      <Route path={`${IRPRODUCTS_PATHS.ROOT}/*`} element={<IRProductsRouter/>} />
      <Route path={`${PLANOGRAM_PATHS.ROOT}/*`} element={<PlanogramRouter/>} />
      <Route path={`${DATA_CONFIG_PATHS.ROOT}/*`} element={<DataConfigurationPage/>} />
      <Route path="users-management/*" element={<UsersManagementRouter/>} />
      <Route path={`${WEBSHOP_PATHS.ROOT}/*`} element={<WebShopRouter/>} />
      {/* <Route path="settings" element={<SettingsPage/>} /> */}
      <Route path="*" element={<Navigate to="/error"/>}/>
    </>
  ) : (
    <>
      <Route path="*" element={<LayoutSplashScreen />}/>
    </>
  );
}
