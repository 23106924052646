const FILE_ICON_PATH = '/media/svg/files';
export const IMAGE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

export const getFileIcon = (mimeType:string) => {
    switch (mimeType) {
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return `${FILE_ICON_PATH}/ppt.svg`;

        case 'application/msword':
        case 'application/doc':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return `${FILE_ICON_PATH}/doc.svg`;

        case 'application/pdf':
            return '/media/svg/files/pdf.svg';

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
            return `${FILE_ICON_PATH}/xls.svg`;

        case 'application/xml':
            return `${FILE_ICON_PATH}/xml.svg`;

        case 'text/csv':
            return `${FILE_ICON_PATH}/csv.svg`;

        case 'audio/mp4':
        case 'video/mp4':
            return `${FILE_ICON_PATH}/mp4.svg`;

        case 'image/jpeg':
        case 'image/jpg':
            return `${FILE_ICON_PATH}/jpg.svg`;

            
        case 'image/png':
            return `${FILE_ICON_PATH}/png.svg`;

        case 'application/zip':
            return `${FILE_ICON_PATH}/zip.svg`;

        case 'text/html':
            return `${FILE_ICON_PATH}/html.svg`;
            
        case 'text/css':
            return `${FILE_ICON_PATH}/css.svg`;

        case 'text/javascript':
            return `${FILE_ICON_PATH}/javascript.svg`;

        default:
            return '';
    }
}